$(function() {
  $(".select2-ncm-sh-8").select2({
    ajax: {
      url: "/ncms/search.json?sh=sh_8",
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return {
          term: params.term
        };
      },
      processResults: function (data) {
        return { results: $.map( data["ncms"], function(ncm) {
            return { id: ncm.id, text: ncm.formated_code + ' - ' + ncm.description }
          } ) };
      },
      cache: true
    },
    minimumInputLength: 2,
    placeholder: 'Selecionar NCM',
    width: '100%',
    diacritics: true,
    allowClear: true
  });

  $(".select2-hts-sh-6").select2({
    ajax: {
      url: "/hts_records/search.json?sh=sh_6",
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return {
          term: params.term
        };
      },
      processResults: function (data) {
        return { results: $.map( data["hts"], function(hts) {
            return { id: hts.id, text: hts.formated_code + ' - ' + hts.description }
          } ) };
      },
      cache: true
    },
    minimumInputLength: 2,
    placeholder: 'Selecionar',
    width: '100%',
    diacritics: true,
    allowClear: true
  });

  $(".select2-select").select2({})
});
