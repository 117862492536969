var indexOf =
  [].indexOf ||
  function (item) {
    for (var i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) return i;
    }
    return -1;
  };

$(function () {
  return $(document).on(
    "blur change paste cut",
    ".autocomplete_cep",
    function () {
      let $campo, $fields, cep;
      $fields = $(this).closest(".nested-fields");
      $campo = $fields.find(".notice-error");
      cep = $(this).val().replace(/\D/g, "");
      if (cep.length === 8) {
        $.getJSON(
          "//viacep.com.br/ws/" + cep + "/json/?callback=?",
          function (dados) {
            if (dados.cep !== undefined) {
              $fields.find(".autocomplete_country").val("Brazil");
              $fields.find(".autocomplete_street").val(dados.logradouro);
              $fields.find(".autocomplete_neighborhood").val(dados.bairro);
              $fields.find(".autocomplete_city").val(dados.localidade);
              $fields.find(".autocomplete_state").val(dados.uf);
              $fields
                .find(".autocomplete_street_number")
                .val(dados.complemento);
              $fields.find(".autocomplete_complement").val("");
              $campo.text("");
            } else {
              $(".address-autocomplete input").val("");
              return $campo.text("CEP não encontrado");
            }
          }
        );
      } else if ($(this).val() === "") {
        $(".address-autocomplete input").val("");
        return $campo.text("");
      } else {
        return $campo.text("CEP inválido. Verifique a quantidade de dígitos.");
      }
    }
  );
});
