function aplicar_mascara() {
  $("input.mask_cnpj").mask("99.999.999/9999-99");
  $("input.mask_cpf").mask("999.999.999-99");
  $("input.mask_phone").mask("(99) 99999-9999");
  $("input.mask_pis").mask("999.99999.99-9");
  $("input.mask_zip_code").mask("99999-999");
  $("input.mask_date").mask("99/99/9999");
  $("input.mask_hts").mask("9999.99")
  $("input.di_number").mask("99/9999999-9")

  $("input.valor").maskMoney({
    allowNegative: false,
    thousands: "",
    decimal: ",",
    affixesStay: false,
  });

  $("input.valor_pesos").maskMoney({
    allowNegative: false,
    thousands: "",
    decimal: ",",
    affixesStay: false,
    precision: 4,
  });

  $("input.percent").maskMoney({
    allowNegative: false,
    thousands: "",
    decimal: ".",
    affixesStay: false,
    allowZero: true,
  });

  $("input.valor_com_simbolo").maskMoney({
    allowNegative: false,
    thousands: ".",
    decimal: ",",
    affixesStay: true,
    prefix: "R$",
  });

  $("input.valor_with_zero").maskMoney({
    allowNegative: false,
    thousands: "",
    decimal: ",",
    affixesStay: false,
    allowZero: true,
  });

  $("input.valor_com_simbolo, input.valor_pesos, input.valor, input.valor_with_zero, input[class*='mask_'").addClass("remove_mask_campo");
  $("form.remove_mask input.valor_com_simbolo").maskMoney("mask");
  $("input.mask_data").removeClass("remove_mask_campo");
  return $("input[class*='mask_'").trigger("input");
}

$(function () {
  aplicar_mascara();
  $(document).on("cocoon:after-insert", function (event) {
    return aplicar_mascara();
  });
  $(document).on("page:load", function () {
    return aplicar_mascara();
  });
  $(document).on("cocoon:after-insert", function (event) {
    return aplicar_mascara();
  });
  return $("form.remove_mask").submit(function () {
    return $("form input.remove_mask_campo").each(function (index) {
      var sem_mascara;
      sem_mascara = $(this).maskMoney("unmasked")[0];
      if ($(this).data("mask") != null) {
        sem_mascara = $(this).cleanVal();
      }
      if (sem_mascara != null && sem_mascara !== 0) {
        return $(this).val(sem_mascara);
      }
    });
  });
});
