require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("@nathanvda/cocoon");
require("jquery-ui");
require("chart.js");

import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/all";
import "./custom";
import "./bootstrap_custom.js";
import "./autocomplete_cnpj";
import "./autocomplete_zipcode";
import "select2";
import "./select_all";
import "sweetalert2/src/sweetalert2.js";
import "./mask";
import "./jquery.mask.min";
import "./jquery.maskMoney.min";
import "./commercial_details";
import "./scripts";

global.$ = require("jquery");
global.toastr = require("toastr");
global.Swal = require("sweetalert2");
global.Dropzone = require("dropzone");

require.context("../images", true);
