$(function() {
  $('#order_commercial_detail_attributes_payment_methods').on('change', function () {
    $(".box-payment-methods").hide();
    if (this.value !== ""){
      $(`.${this.value}`).toggle();
    }
  });

  let persistedValue = $('#order_commercial_detail_attributes_payment_methods').val();
  if (persistedValue !== ""){
    $(".box-payment-methods").hide();
    if (persistedValue !== ""){
      $(`.${persistedValue}`).toggle();
    }
  }
});