var indexOf =
  [].indexOf ||
  function (item) {
    for (var i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) return i;
    }
    return -1;
  };

$(function () {
  return $(document).on(
    "blur change paste cut",
    ".autocomplete_cnpj",
    function () {
      let cnpj;
      cnpj = $(this).val().replace(/\D/g, "");
      if (cnpj.length === 14) {
        $("#loadingInfo").modal("show");
        $.ajax({
          url: `/big_data_searches/company_info?document_number=${cnpj}`,
          type: "GET",
          dataType: "json",
          success: function (data) {
            $(".autocomplete_fantasy_name").val(
              data["response"]["basic_data"]["TradeName"]
            );
            $(".autocomplete_company_name").val(
              data["response"]["basic_data"]["OfficialName"]
            );

            $(".autocomplete_zip_code").val(
              data["response"]["address"]["ZipCode"]
            );
            $(".autocomplete_complement").val(
              data["response"]["address"]["Complement"]
            );
            $(".autocomplete_state").val(data["response"]["address"]["State"]);
            $(".autocomplete_country").val(
              data["response"]["address"]["Country"]
            );
            $(".autocomplete_city").val(data["response"]["address"]["City"]);
            $(".autocomplete_neighborhood").val(
              data["response"]["address"]["Neighborhood"]
            );
            $(".autocomplete_street").val(
              `${data["response"]["address"]["Typology"]} ${data["response"]["address"]["AddressMain"]}`
            );
            $(".autocomplete_street_number").val(
              data["response"]["address"]["Number"]
            );

            $(".autocomplete_responsible").val(
              data["response"]["relationship"]["RelatedEntityName"]
            );
            $(".autocomplete_responsible_email").val(
              data["response"]["email"]["EmailAddress"]
            );
            $(".autocomplete_responsible_phone").val(
              `${data["response"]["phone"]["AreaCode"]} ${data["response"]["phone"]["Number"]}`
            );
            $("#loadingInfo").modal("hide");
          },
          error: function (data) {
            $("#loadingInfo").modal("hide");
            Swal.fire("Dados não encontrados para o CNPJ", "", "error");
          },
        });
        return false;
      }
    }
  );
});
